import React, { useEffect, useRef } from 'react'
import { PayoutHistory } from '../../../types/User'
import {
  ActiveElement,
  Chart,
  ChartConfiguration,
  ChartEvent,
} from 'chart.js/auto'

import useLocale from '../../../hooks/useLocale'
import { DateFormat, formatDateString } from '../../../lib/utils/formatDate'
import { getPayoutsHistory, useSelector } from '../../../store'
import { useTranslation } from 'next-i18next'
import routes from '../../../config/routes'
import { EnumDictionary } from '../../../lib/utils/enumDictionary'
import {
  PayoutCryptoCurrency,
  PayoutCurrency,
  PayoutDistributionTransactionErrorFlags,
  PayoutFiatCurrency,
} from '../../../types/Payout'
import { formatCurrency } from '../../../lib/utils/formatNumber'

export default function PayoutsHistoryBarCharts() {
  const { t } = useTranslation('home')
  const { locale } = useLocale()

  const PayoutDistributionTransactionErrorFlagsText: Record<
    PayoutDistributionTransactionErrorFlags,
    string
  > = {
    [PayoutDistributionTransactionErrorFlags.USER_NOT_FOUND]: t(
      'dashboardPreview.yieldHistoryCard.pendingRewards.payoutErrors.userNotFound',
    ),
    [PayoutDistributionTransactionErrorFlags.USER_NOT_VERIFIED]: t(
      'dashboardPreview.yieldHistoryCard.pendingRewards.payoutErrors.userNotVerified',
    ),
    [PayoutDistributionTransactionErrorFlags.USER_PAYOUT_METHOD_NOT_FOUND]: t(
      'dashboardPreview.yieldHistoryCard.pendingRewards.payoutErrors.userPayoutMethodNotFound',
    ),
    [PayoutDistributionTransactionErrorFlags.FEES_TOO_HIGH]: t(
      'dashboardPreview.yieldHistoryCard.pendingRewards.payoutErrors.feesTooHigh',
    ),
    [PayoutDistributionTransactionErrorFlags.PROPERTY_PAYOUT_METHOD_NOT_FOUND]:
      t(
        'dashboardPreview.yieldHistoryCard.pendingRewards.payoutErrors.propertyPayoutMethodNotFound',
      ),
    [PayoutDistributionTransactionErrorFlags.PROPERTY_MISSING_FUNDS]: t(
      'dashboardPreview.yieldHistoryCard.pendingRewards.payoutErrors.propertyMissingFunds',
    ),
    [PayoutDistributionTransactionErrorFlags.DISTRIBUTION_ERROR]: t(
      'dashboardPreview.yieldHistoryCard.pendingRewards.payoutErrors.distributionError',
    ),
  }

  const payoutsHistory = useSelector(getPayoutsHistory)

  const chartRef = useRef<HTMLCanvasElement | null>(null)
  const chartInstanceRef = useRef<Chart | null>(null)

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }

      const ctx = chartRef.current.getContext('2d')
      if (ctx) {
        const eurosGradient = ctx.createLinearGradient(
          0,
          0,
          0,
          chartRef.current.height,
        )
        eurosGradient.addColorStop(0, '#2B2E9A')
        eurosGradient.addColorStop(1, '#846AC6')

        const dollarsGradient = ctx.createLinearGradient(
          0,
          0,
          0,
          chartRef.current.height,
        )
        dollarsGradient.addColorStop(0, '#1E5631') // Deep green
        dollarsGradient.addColorStop(1, '#A4DE02') // Vibrant green

        const errorGradient = ctx.createLinearGradient(
          0,
          0,
          0,
          chartRef.current.height,
        )
        errorGradient.addColorStop(0, '#9A2B2E') // Rouge foncé
        errorGradient.addColorStop(1, '#C66A6A') // Rouge clair

        const barStyle: EnumDictionary<PayoutCurrency, CanvasGradient> = {
          [PayoutCryptoCurrency.USDC]: dollarsGradient,
          [PayoutFiatCurrency.EUR]: eurosGradient,
        }

        const lastData = payoutsHistory.slice(-12) // Last 12 months
        const labels = lastData.map((item: PayoutHistory) =>
          formatDateString(item.paidAt, locale, DateFormat.MONTH_XS),
        )

        // Find all unique currencies in the payout history
        const currencies = new Set<PayoutCurrency>()
        lastData.forEach((item) => {
          Object.keys(item.payouts || {}).forEach((currency) => {
            currencies.add(currency as PayoutCurrency)
          })
        })

        // Create datasets dynamically for each currency
        const datasets = Array.from(currencies).map((currency) => {
          const currencyData = lastData.map(
            (item) => item.payouts?.[currency]?.totalAmount / 100 || null,
          )

          return {
            label: currency,
            data: currencyData,
            lineTension: 1,
            backgroundColor: barStyle[currency] || '#f0f0f0',
            stack: 'stack1',
            borderRadius: 5,
            barThickness: 30,
          }
        })

        const errorData = lastData.map((item) =>
          item.errorPayouts.grossEurTotal > 0
            ? item.errorPayouts.grossEurTotal / 100
            : null,
        )

        if (errorData.some((val) => val !== null)) {
          datasets.push({
            label: PayoutFiatCurrency.EUR,
            data: errorData,
            lineTension: 1,
            backgroundColor: errorGradient,
            stack: 'stack1',
            borderRadius: 5,
            barThickness: 30,
          })
        }

        const data = {
          labels: labels,
          datasets: datasets,
        }

        const chartConfig: ChartConfiguration = {
          type: 'bar',
          data: data,
          options: {
            onClick: (event: ChartEvent, elements: ActiveElement[]) => {
              if (elements.length > 0) {
                const index = elements[0].index
                const payout = lastData[index]
                if (payout) {
                  window.location.href = routes.dashboard
                }
              }
            },
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              x: {
                type: 'category',
                grid: {
                  display: false,
                },
                ticks: {
                  padding: -1.5,
                },
              },
              y: {
                beginAtZero: true,
                display: false,
                grace: '20%',
                stacked: true,
                grid: {
                  display: false,
                },
                border: {
                  display: false,
                },
              },
            },
            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              tooltip: {
                callbacks: {
                  title: function (context: any) {
                    return formatDateString(
                      lastData[context[0].dataIndex].paidAt,
                      locale,
                      DateFormat.PLAIN_DATE,
                    )
                  },
                  label: function (context: any) {
                    const value = context.parsed.y || 0
                    const currency = context.dataset.label as PayoutCurrency

                    if (context.dataset.stack === 'stack2') {
                      const dataEntry = lastData?.[context.dataIndex]
                      const errorPayouts = dataEntry?.errorPayouts
                      const errorFlags = errorPayouts?.errorFlags

                      if (
                        !dataEntry ||
                        !errorPayouts ||
                        !Array.isArray(errorFlags)
                      ) {
                        return [
                          `${t(
                            'dashboardPreview.yieldHistoryCard.pendingRewards.title',
                          )} ${formatCurrency(
                            value,
                            2,
                            false,
                            currency,
                            locale,
                          )}`,
                        ]
                      }

                      const errorMessages = errorFlags.map((flag) =>
                        PayoutDistributionTransactionErrorFlagsText?.[flag]
                          ? `• ${PayoutDistributionTransactionErrorFlagsText[flag]}`
                          : `• ${t(
                              'dashboardPreview.yieldHistoryCard.pendingRewards.payoutErrors.unknownError',
                            )}`,
                      )

                      return [
                        `${t(
                          'dashboardPreview.yieldHistoryCard.pendingRewards.title',
                        )} ${formatCurrency(
                          value,
                          2,
                          false,
                          currency,
                          locale,
                        )}`,
                        ...errorMessages,
                      ]
                    }

                    return `${formatCurrency(
                      value,
                      2,
                      false,
                      currency,
                      locale,
                    )}`
                  },
                },
              },
            },
          },
        }

        chartInstanceRef.current = new Chart(ctx, chartConfig)
      }
    }

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }
    }
  }, [payoutsHistory, locale])

  if (payoutsHistory.length < 1) {
    return (
      <div className="relative flex h-full flex-col items-center justify-center space-y-1.5 p-3">
        <img
          src="/img/icons/3d/sale-min.png"
          className=" h-28 w-auto -rotate-6 animate-hover"
          alt="sale icon 3d"
        />
        <p className="text-center text-sm font-light text-neutralBlack/80">
          {t('dashboardPreview.yieldHistoryCard.noPayoutYet')}
        </p>
      </div>
    )
  }

  return <canvas ref={chartRef} />
}
